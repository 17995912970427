@media(min-width: 1024px) {
    .wrapperUp {
        padding: 51px 284px 50px;
        width: 1440px;
        margin: auto;
        display: flex;
        justify-content: space-between;
    }

    .signUpWrapper {
        width: 871px;
        background: #FFFFFF;
        border: 1px solid #F1F1F1;
        box-sizing: border-box;
        border-radius: 4px;
        display: flex;
        /*justify-content: center;*/
        padding: 35px 0 45px 0;
        flex-direction: column;
        align-items: center;
    }

    .signUpBlockForm {
        display: flex;
        flex-wrap: wrap;
        padding: 10px 145px 10px 145px;
    }

    .signUpBlockForm input {
        width: 276px;
    }

    .toSignUpBlockForm input {
      margin-bottom: 26px;
    }

    .signUpBlockForm span {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #696969;
    }

    .signUpBlockForm input:nth-child(2n+1) {
        margin-right: 12px;
        margin-bottom: 16px;
    }

    .createButton {
        display: flex;
        margin-top: 10px;
        flex-direction: column;
        align-items: center;
    }
    .createButton p {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 13px;
        text-align: center;
        color: #696969;
        width: 338px;
    }

    .fidelity {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .fidelity p {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #696969;
    }
}

@media(max-width: 768px) {
    .wrapperUp {
        /*padding: 51px 284px 50px;*/
        /*width: 1440px;*/
        margin: auto;
        display: flex;
        justify-content: space-between;
    }

    .signUpWrapper {
        width: 100%;
        text-align: center;
        /*height: 521px;*/
        background: #FFFFFF;
        border: 1px solid #F1F1F1;
        box-sizing: border-box;
        border-radius: 4px;
        display: flex;
        /*justify-content: center;*/
        padding: 35px 21px 45px 21px;
        flex-direction: column;
        align-items: center;
    }

    .signUpBlockForm {
        display: block;
    }

    .signUpBlockForm input {
        width: 100%;
    }

    .signUpBlockForm input:nth-child(2n+1) {
        margin-right: 12px;
        margin-bottom: 16px;
    }

    .createButton {
        display: flex;
        margin-top: 53px;
        flex-direction: column;
        align-items: center;
    }
    .createButton p {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 13px;
        text-align: center;
        color: #696969;
    }

    .fidelity {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .fidelity p {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #696969;
    }
}

@media(min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .wrapperUp {
    padding: 51px 80px 50px;
  }
}
